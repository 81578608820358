import React from 'react'
import SlidersIcon from '../../assets/images/icons/sliders.svg'

const FiltersV2 = () => {
  return (
    <div>
        <button className='flex shadow-lg hover:shadow-xl hover:shadow-doiteasy-blue/5 transition duration-200 active:shadow-none shadow-doiteasy-blue/5 items-center justify-center gap-x-[16px] rounded-[20px] font-space-grotesk bg-white px-[24px] py-[16px] '>
            Filters
            <div className='w-[30px] h-[30px] rounded-full bg-[#F0F0F080] flex items-center justify-center'>
                <img src={SlidersIcon} className='w-[16px] h-[16px]' alt='' />
            </div>
        </button>
    </div>
  )
}

export default FiltersV2