import React from 'react'
import { authHeader, baseUrl, userDetails } from '../../utils';
import axios from "axios"
import { useNavigate } from "react-router-dom"
// import ErrorMessage from '../elements/ErrorMessage';
// import Avatar from '../../assets/images/placeholder-avatar-male.jpg'
import { useDispatch } from 'react-redux';
import { ERROR } from '../../store/types';
import DropdownMenu from '../elements/DropdownMenu';
import Avatar from '../../assets/images/placeholder-avatar-male.jpg'
// import EnvelopeIcon from '../../assets/images/icons/envelope-icon.svg'
import CogOutlineIcon from '../../assets/images/icons/cog-outline-icon.svg'
import NotificationIcon from '../../assets/images/icons/notification-outline-icon.svg'
import HelpIcon from '../../assets/images/icons/help-outline-icon.svg'
import SearchField from '../elements/SearchField';
import PlusWhite from '../../assets/images/icons/plus.svg';
import SupplierGrayscale from '../../assets/images/icons/supplier-icon.svg'
import HeartPlusGrayscale from '../../assets/images/icons/heart-plus-icon.svg'
import VisitorsGrayscale from '../../assets/images/icons/visitors-icon.svg'
import UserIconGrayscale from '../../assets/images/icons/user-icon.svg'
import LogoutIcon from '../../assets/images/icons/logout.svg'

const AdminHeader = ({pageTitle, sidebarActive, sidebarToggleFunction}) => {
    let navigate = useNavigate()    
    const dispatch = useDispatch()

    const logOut = async () => {
        try {
            const headers = authHeader()
            
            const response = await axios.delete(`${baseUrl}/auth/sessions`, {}, { headers })            
            if(!response.data.status) {
                dispatch({
                    type: ERROR,
                    error: {response: {data: {
                        message: response.message
                    }}}
                })
            }
        } catch (error) {
            if(error.response.status === 401 || error.response.status === 403) {
                navigate("/")
            } else {
                dispatch({
                    type: ERROR,
                    error: {response: {data: {
                        message: error.response.data.message
                    }}}
                })
            }
        }
    }

    const userDropdownOptions = [
        {
            icon: LogoutIcon,
            label: "Log out",
            action: logOut
        }
    ]

    const addEntityDropdownOptions = [
        {
            icon: UserIconGrayscale,
            label: "Enrol Resident",
            action: ()=>{navigate(`/admin/care-home/residents/enrol-resident`)}
        },
        {
            icon: HeartPlusGrayscale,
            label: "Enrol Caregiver",
            action: ()=>{}
        },
        {
            icon: VisitorsGrayscale,
            label: "Record a Visit",
            action: ()=>{}
        },
        {
            icon: SupplierGrayscale,
            label: "Add Supplier",
            action: ()=>{}
        },
    ]

    const user = userDetails()

    return (
        <div className="p-3 h-20 z-20 flex bg-white items-center justify-between flex-row gap-x-4 relative">
            <div>
                <h1 className="ml-[10px] text-lg text-doiteasy-black font-space-grotesk text-primary font-medium w-1/4">{pageTitle}</h1>
            </div>

            <div className='w-full flex flex-row-reverse items-center'>
                <DropdownMenu 
                    buttonType="icon"
                    buttonIcon={PlusWhite}
                    menuItems={addEntityDropdownOptions}
                    buttonClasses="flex items-center justify-center w-[34px] h-[34px] rounded-full bg-[#2684FC]"
                    iconClasses="w-[15px] h-[15px]"
                />
                <div className='px-[24px] w-7/12'>
                    <SearchField placeholderText={`Search for anything`} />
                </div>
            </div>

            <div className="w-max flex flex-row-reverse items-center">
                <div className='pl-[24px] min-w-[250px]'>
                    <DropdownMenu 
                        buttonType="combo"
                        buttonText={user.name.split(' ').length > 2 ? `${user.name.split(' ')[0]} ${user.name.split(' ')[1].charAt(0)}. ${user.name.split(' ')[2]}` : user.name }
                        buttonIcon={Avatar}
                        menuItems={userDropdownOptions}
                        buttonClasses="flex items-center gap-x-[8px]"
                        iconClasses="rounded-full w-[32px] h-[32px]"
                    />
                </div>

                {/* <div className='w-[1px] h-[32px] bg-gray-200' />

                <div className='px-[24px]'>
                    <button>
                        <img src={EnvelopeIcon} alt='' />
                    </button>
                </div> */}

                <div className='w-[1px] h-[32px] bg-gray-200' />

                <div className='px-[24px] flex flex-row items-center w-max gap-x-[18px]'>
                    <button>
                        <img src={HelpIcon} alt='' className='w-[30px]' />
                    </button>
                    <button>
                        <img src={NotificationIcon} alt='' className='w-[30px]' />
                    </button>
                    <button>
                        <img src={CogOutlineIcon} alt='' className='w-[30px]' />
                    </button>
                </div>

                {/* <div className='w-[1px] h-[32px] bg-gray-200' /> */}

            </div>
        </div>
    )
}

export default AdminHeader
