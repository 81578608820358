import React from 'react'
import CheckIcon from '../icons/CheckIcon'
const Checkbox = ({CheckboxLabel, checkboxToggleFunction, isChecked, hasError}) => {
  return (
    <div onClick={checkboxToggleFunction} className='w-full flex items-start gap-x-3 my-2 cursor-pointer'>
      <div className='w-[26px]'>
        <button 
            className={`mt-[2px] rounded flex items-center justify-center border transition duration-200 text-white 
            ${isChecked ? 'bg-doiteasy-blue border-doiteasy-blue' : 'bg-transparent border-gray-400'}
            ${hasError ? 'border-red-600' : 'border-gray-400'}`
          } 
          style={{width: '25px', height: '25px'}}
          onClick={checkboxToggleFunction}
        >
            {isChecked && <CheckIcon />}
        </button>
      </div>
      <label className={`text-doiteasy-black text-[14px]`}>
        {CheckboxLabel}
      </label>
    </div>
  )
}

export default Checkbox