import React from 'react'
import Layout from '../../../../components/layouts/Layout'
import FiltersV2 from '../../../../components/elements/FiltersV2'
import PlusWhite from '../../../../assets/images/icons/plus.svg';
import PrimaryButton from '../../../../components/elements/PrimaryButton';
import EmptyState from '../../../../components/elements/EmptyState';
import { Link } from 'react-router-dom';

const Residents = () => {
  return (
    <Layout pageTitle="Residents">
      <div className='w-full'>
        <div className='flex items-center justify-between'>
          <div className='w-1/2'></div>
          <div className='w-1/2 flex flex-row-reverse gap-x-[10px]'>
            <Link to={`enrol-resident`}>
              <PrimaryButton label={`Enrol resident`} icon={PlusWhite} />
            </Link>
            <FiltersV2 />
          </div>
        </div>

        <div className='bg-white p-[50px] mt-[20px] rounded-[20px]'>
          <div className='w-1/2 mx-auto'>
            <EmptyState emptyStateTitle={`No Residents Found`} emptyStateText={`It seems you have not enrolled any residents yet. If you need to enroll a resident right now, use the "Enrol resident" button at the top right`} />
          </div>
        </div>
        
      </div>
    </Layout>
  )
}

export default Residents