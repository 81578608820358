import React, { useEffect, useState } from 'react'
import Layout from '../../../../components/layouts/Layout'
import TextField from '../../../../components/elements/form/TextField';
import CheckIcon from '../../../../components/elements/icons/CheckIcon';
import FormButton from '../../../../components/elements/form/FormButton';
import ArrowIcon from '../../../../components/elements/icons/ArrowIcon';
import { activeCareHome } from '../../../../utils';
import DateField from '../../../../components/elements/form/DateField';
import SelectField from '../../../../components/elements/form/SelectField';
import RadioGroup from '../../../../components/elements/form/RadioGroup';
import { useDispatch, useSelector } from 'react-redux';
import { ERROR, SET_SUCCESS } from '../../../../store/types';
import { clearCreatedResident, createResident } from '../../../../store/actions/residentsActions';
import PlusIcon from '../../../../components/elements/icons/PlusIcon';
import TrashIcon from '../../../../components/elements/icons/TrashIcon';
import TextareaField from '../../../../components/elements/form/TextareaField';
import Checkbox from '../../../../components/elements/form/Checkbox';
import moment from 'moment';
import { Switch } from '@headlessui/react';


const NewResident = () => {
  const steps = [
    {
        title: "Biodata",
        description: "Please provide details for your user account."
    },
    {
        title: "Health Information",
        description: "Tell us a bit about your care home"
    },
    {
        title: "Next of Kin",
        description: "Resident's closest living relative, who has the authority or responsibility to make decisions on behalf of the them in case they become incapacitated, deceased, or otherwise unable to act for themselves"
    },
    {
        title: "Emergency Contacts",
        description: "Add at least one Contact asides from the resident's medical doctor and next of kin that we can reach in case of emergencies. This should ideally be people that will be available or live/work close proximity"
    },
    {
        title: "Notes & Consent",
        description: "Add any important notes for this resident and receive their consent to our terms to finish onboarding this resident"
    },
  ]

  const titles = [
    {
      label: 'Mr.',
      value: 'mr',
    },
    {
      label: 'Mrs.',
      value: 'mrs',
    },
    {
      label: 'Miss',
      value: 'miss',
    },
    {
      label: 'Dr.',
      value: 'dr',
    },
    {
      label: 'Prof.',
      value: 'prof',
    },
    {
      label: 'Engr.',
      value: 'engr',
    },
    {
      label: 'Other',
      value: 'other',
    },
  ]

  const religions = [
    {
      label: "Christianity",
      value: "christianity"
    },
    {
      label: "Islam",
      value: "islam"
    },
    {
      label: "Hinduism",
      value: "hinduism"
    },
    {
      label: "Judaism",
      value: "judaism"
    },
    {
      label: "Atheism",
      value: "atheism"
    },
    {
      label: "Other",
      value: "other"
    },
  ]

  const relationships = [
    {
      label: 'Parent',
      value: 'parent',
    },
    {
      label: 'Grandparent',
      value: 'grandparent',
    },
    {
      label: 'Child',
      value: 'child',
    },
    {
      label: 'Grandparent',
      value: 'grandparent',
    },
    {
      label: 'Grandchild',
      value: 'grandchild',
    },
    {
      label: 'Sibling',
      value: 'sibling',
    },
    {
      label: 'Nephew',
      value: 'nephew',
    },
    {
      label: 'Niece',
      value: 'niece',
    },
    {
      label: 'Other',
      value: 'other',
    },
  ]

  const genders = [
    {
      label: 'Female',
      value: 'female',
    },
    {
      label: 'Male',
      value: 'male',
    },
  ]

  const maritalStatusOptions = [
    {
      label: 'Single',
      value: 'single',
    },
    {
      label: 'Married',
      value: 'married',
    },
    {
      label: 'Divorced',
      value: 'divorced',
    },
    {
      label: 'Widowed',
      value: 'widowed',
    },
    {
      label: 'Civil Union',
      value: 'civil-union',
    },
    {
      label: 'Other',
      value: 'other',
    },
  ]
  
  const dispatch = useDispatch()
  const residentsSelector = useSelector(state => state.residents)

  const [activeStep, setActiveStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [residentPayload, setResidentPayload] = useState({});

  useEffect(() => {
    if(residentsSelector.createdResident !== null) {
      setCompletedSteps([...completedSteps, activeStep])
      dispatch({
        type: SET_SUCCESS,
        payload: 'Resident information saved successfully'
      })
      setResidentPayload(residentsSelector.createdResident)
      setTimeout(() => {
        dispatch(clearCreatedResident())
      }, 200);
      setActiveStep(activeStep + 1)
    }

    return () => {
      
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [residentsSelector.createdResident, dispatch]);

  const [hasAllergy, setHasAllergy] = useState(false);
  const [allergies, setAllergies] = useState(['']);

  const addAllergy = () => {
    let temp = [...allergies]
    temp.push('')
    setAllergies(temp)
  }

  const updateAllergy = (index, value) => {
    let temp = [...allergies]
    temp[index] = value
    setAllergies(temp)
  }

  const removeAllergy = (index) => {
    let temp = [...allergies]
    temp.splice(index, 1)
    setAllergies(temp)
  }

  const [hasPreExistingCondition, setHasPreExistingCondition] = useState(false);
  const [preExistingConditions, setPreExistingConditions] = useState(['']);

  const addCondition = () => {
    let temp = [...preExistingConditions]
    temp.push('')
    setPreExistingConditions(temp)
  }

  const updateCondition = (index, value) => {
    let temp = [...preExistingConditions]
    temp[index] = value
    setPreExistingConditions(temp)
  }

  const removeCondition = (index) => {
    let temp = [...preExistingConditions]
    temp.splice(index, 1)
    setPreExistingConditions(temp)
  }

  const [notes, setNotes] = useState(['']);

  const addNote = () => {
    let temp = [...notes]
    temp.push('')
    setNotes(temp)
  }

  const updateNote = (index, value) => {
    let temp = [...notes]
    temp[index] = value
    setNotes(temp)
  }

  const removeNote = (index) => {
    let temp = [...notes]
    temp.splice(index, 1)
    setNotes(temp)
  }

  const [validationErrors, setValidationErrors] = useState({});
  const [primaryPhysician, setPrimaryPhysician] = useState({});

  const validateForm = () => {
    let errors = {}

    if(activeStep === 0) {
      if(!residentPayload.title || residentPayload.title === ''){
        errors.title = true
      }
  
      if(!residentPayload.firstName || residentPayload.firstName === ''){
        errors.firstName = true
      }
  
      if(!residentPayload.lastName || residentPayload.lastName === ''){
        errors.lastName = true
      }
  
      if(!residentPayload.dob || residentPayload.dob === ''){
        errors.dob = true
      }
  
      if(!residentPayload.gender || residentPayload.gender === ''){
        errors.gender = true
      }
  
      if(!residentPayload.religion || residentPayload.religion === ''){
        errors.religion = true
      }
  
      if(!residentPayload.maritalStatus || residentPayload.maritalStatus === ''){
        errors.maritalStatus = true
      }
  
      if(!residentPayload.email || residentPayload.email === ''){
        errors.email = true
      }
  
      if(!residentPayload.phone || residentPayload.phone === ''){
        errors.phone = true
      }
  
      if(!residentPayload.address || residentPayload.phone === ''){
        errors.address = true
      }
  
      if(!residentPayload.city || residentPayload.city === ''){
        errors.city = true
      }
  
      if(!residentPayload.zip || residentPayload.zip === ''){
        errors.zip = true
      }
  
      if(!residentPayload.state || residentPayload.state === ''){
        errors.state = true
      }
    } else if(activeStep === 1) {
      if(hasAllergy){
        allergies.forEach((allergy, allergyIndex)=> {
          if(!allergy || allergy === ''){
            errors[`allergy-${allergyIndex}`] = true
          }
        })
      }

      if(hasPreExistingCondition){
        preExistingConditions.forEach((condition, conditionIndex)=> {
          if(!condition || condition === ''){
            errors[`condition-${conditionIndex}`] = true
          }
        })
      }

      if(!primaryPhysician.name || primaryPhysician.name === ''){
        errors.physicianName = true
      }

      if(!primaryPhysician.hospital || primaryPhysician.hospital === ''){
        errors.physicianHospital = true
      }

      if(!primaryPhysician.email || primaryPhysician.email === ''){
        errors.physicianEmail = true
      }

      if(!primaryPhysician.phone || primaryPhysician.phone === ''){
        errors.physicianPhone = true
      }
    } else if(activeStep === 2) {
      if(!nokPayload.name || primaryPhysician.name === ''){
        errors.nokName = true
      }
      
      if(!nokPayload.relationship || primaryPhysician.relationship === ''){
        errors.nokRelationship = true
      }
      
      if(!nokPayload.gender || primaryPhysician.gender === ''){
        errors.nokGender = true
      }
      
      if(!nokPayload.phone || primaryPhysician.phone === ''){
        errors.nokPhone = true
      }
      
      if(!nokPayload.email || primaryPhysician.email === ''){
        errors.nokEmail = true
      }

    } else if(activeStep === 3) {
      emergencyContacts.forEach((contact, contactIndex)=> {
        if(!contact.name || contact.name === ''){
          errors[`contact-${contactIndex}-name`] = true
        }
        if(!contact.email || contact.email === ''){
          errors[`contact-${contactIndex}-email`] = true
        }
        if(!contact.phone || contact.phone === ''){
          errors[`contact-${contactIndex}-phone`] = true
        }
      })
    } else if(activeStep === 4) {
      if(!consentAcceptance.termsOfStay) {
        errors.termsOfStay = true
      }
    } 


    setValidationErrors(errors)
    return errors
  }

  const pushResident = () => {
    if (Object.values(validateForm()).includes(true)) {
      dispatch({
          type: ERROR,
          error: {response: {data: {
              message: 'Please check the highlighted fields'
          }}}
      })
      return
    }

    let payload = {}

    if(activeStep === 0) {
      payload = {
        
      }
    }

    dispatch(createResident(payload))
  }

  const careHomeDetails = activeCareHome()

  const [nokPayload, setNokPayload] = useState({});

  const emergencyContact = {
    name: "",
    phone: "",
    email: ""
  }

  const [emergencyContacts, setEmergencyContacts] = useState([emergencyContact]);

  const addEmergencyContact = () => {
    let temp = [...emergencyContacts]
    temp.push(emergencyContact)
    setEmergencyContacts(temp)
  }

  const updateEmergencyContact = (index, label, value) => {
    let temp = [...emergencyContacts]
    temp[index][label] = value
    setEmergencyContacts(temp)
  }

  const deleteEmergencyContact = (index) => {
    let temp = [...emergencyContacts]
    temp.splice(index, 1)
    setEmergencyContacts(temp)
  }

  const consents = {
    firstAid: false,
    termsOfStay: false,
    dataPrivacyAndUse: false
  }

  const [consentAcceptance, setConsentAcceptance] = useState(consents);

  const updateConsent = (field, value) => {
    let temp = {...consentAcceptance} 
    temp[field] = value
    setConsentAcceptance(temp)
  }

  return (
    <Layout pageTitle={`Residents`}>
      <div className="w-full min-h-screen flex pt-[10px] justify-center">
        <div className="w-8/12">
            <h3 className="mt-[10px] font-medium text-gray-700 text-xl mb-1 text-center">Enrol a new Resident at {careHomeDetails.careHome.name}</h3>
            <p className="text-sm text-opacity-80 text-center">You can fill each step of the form and save it to continue at a later time.</p>

            <div className='flex items-center justify-between mt-[20px] px-[40px] relative'>
                <div className='w-full h-[2px] bg-blue-200 absolute top-[19px] left-0 z-10'></div>
                {steps.map((step, stepIndex)=>(
                    <div key={stepIndex} className='flex flex-col items-center justify-center z-20'>
                        <div className={`w-[40px] h-[40px] rounded-full flex items-center justify-center text-white border-[5px] border-doiteasy-light-gray ${activeStep === stepIndex ? 'bg-doiteasy-blue' : completedSteps.includes(stepIndex) ? 'bg-green-500' : 'bg-blue-200'}`}>
                            {!completedSteps.includes(stepIndex) ? <p className='font-space-grotesk font-[500]'>{stepIndex + 1}</p> : <CheckIcon className="w-5 h-5 text-white" />}
                        </div>
                        <p className={`mt-[10px] text-sm ${activeStep === stepIndex ? 'font-[600] text-doiteasy-black' : 'font-[500] text-gray-400'}`}>{step.title}</p>
                    </div>
                ))}
            </div>

            <div className='rounded-[32px] bg-white px-[40px] py-[40px] mt-[20px]'>
                <p className="text-sm text-opacity-80 mb-[10px] font-[550] tracking-[0.01em] text-gray-600">{steps[activeStep]?.description || ''}</p>
                {activeStep < 5 && <hr/>}

                {activeStep === 0 && <>
                  <div className='mt-4 flex items-center justify-between gap-x-[20px]'>
                    <div className='w-2/12'>
                      <SelectField
                        selectOptions={titles}
                        inputLabel="Title"
                        titleField="label"
                        displayImage={false}
                        imageField=""
                        placeholderText={`Title`}
                        preSelectedIndex={titles.findIndex(item => item.value === residentPayload.title)}
                        // preSelectedIndex={null}
                        fieldId={`title`}
                        hasError={validationErrors && validationErrors[`title`]}
                        returnFieldValue={(value) => {setResidentPayload({...residentPayload, ...{title: value.value}})}}
                      />
                    </div>
                    {residentPayload.title === 'other' && <div className='w-4/12'>
                      <TextField
                        inputLabel="Other title" 
                        fieldId="other-title" 
                        inputType="text" 
                        preloadValue={''}
                        inputPlaceholder={'Title'}
                        hasError={validationErrors && validationErrors.title} 
                        returnFieldValue={(value)=>{setResidentPayload({...residentPayload, ...{title: value}})}}
                      />
                    </div>}
                    <div className='w-full'>
                      <TextField
                        inputLabel="First name" 
                        fieldId="first-name" 
                        inputType="text" 
                        preloadValue={''}
                        inputPlaceholder={'Given name'}
                        hasError={validationErrors && validationErrors.firstName} 
                        returnFieldValue={(value)=>{setResidentPayload({...residentPayload, ...{firstName: value}})}}
                      />
                    </div>
                  </div>

                  <div className='mt-4 flex items-center justify-between gap-x-[20px]'>
                    <div className='w-full'>
                      <TextField
                        inputLabel="Middle name" 
                        fieldId="middle-name" 
                        inputType="text" 
                        preloadValue={''}
                        inputPlaceholder={`Resident's middle name (optional)`}
                        hasError={false}
                        returnFieldValue={(value)=>{setResidentPayload({...residentPayload, ...{middleName: value}})}}
                      />
                    </div>
                    <div className='w-full'>
                      <TextField
                        inputLabel="Last name" 
                        fieldId="last-name" 
                        inputType="text" 
                        preloadValue={''}
                        inputPlaceholder={`Resident's surname`}
                        hasError={validationErrors && validationErrors.lastName} 
                        returnFieldValue={(value)=>{setResidentPayload({...residentPayload, ...{lastName: value}})}}
                      />
                    </div>
                  </div>

                  <div className='mt-4 flex items-center justify-between gap-x-[20px]'>
                    <div className='w-full'>
                      <DateField
                        inputLabel="Date of birth"
                        fieldId={`dob`}
                        placeholderText={`Choose date`}
                        hasError={validationErrors && validationErrors.dob}
                        returnFieldValue={(value) => {setResidentPayload({...residentPayload, ...{dob: moment(value).format('DD-MM-YYYY')}})}}
                      />
                    </div>

                    <div className='w-full'>
                      <RadioGroup
                        items={genders}
                        inputLabel="Gender"
                        titleField="label"
                        displayImage={false}
                        imageField=""
                        preSelectedIndex={genders.findIndex(item => item.value === residentPayload.gender)}
                        // preSelectedIndex={null}
                        fieldId={`title`}
                        inline={true}
                        hasError={validationErrors && validationErrors[`gender`]}
                        showDescriptions={true}
                        returnSelected={(value) => {setResidentPayload({...residentPayload, ...{gender: value.value}})}}
                      />
                    </div>
                  </div>

                  <div className='mt-4 flex items-center justify-between gap-x-[20px]'>
                    <div className='w-full'>
                      <SelectField
                        selectOptions={religions}
                        inputLabel="Religion"
                        titleField="label"
                        displayImage={false}
                        placeholderText={`Select religion`}
                        imageField=""
                        preSelectedIndex={religions.findIndex(item => item.value === residentPayload.complexion)}
                        // preSelectedIndex={null}
                        fieldId={`religion`}
                        hasError={validationErrors && validationErrors[`religion`]}
                        returnFieldValue={(value) => {setResidentPayload({...residentPayload, ...{religion: value.value}})}}
                      />
                    </div>

                    <div className='w-full'>
                      <TextField
                        inputLabel="Occupation" 
                        fieldId="occupation" 
                        inputType="text" 
                        preloadValue={''}
                        inputPlaceholder={`Resident's occupation`}
                        hasError={validationErrors && validationErrors.occupation} 
                        returnFieldValue={(value)=>{setResidentPayload({...residentPayload, ...{occupation: value}})}}
                      />
                    </div>

                    <div className='w-full'>
                      <SelectField
                        selectOptions={maritalStatusOptions}
                        inputLabel="Marital Status"
                        titleField="label"
                        displayImage={false}
                        imageField=""
                        placeholderText={`Select marital status`}
                        preSelectedIndex={maritalStatusOptions.findIndex(item => item.value === residentPayload.maritalStatus)}
                        // preSelectedIndex={null}
                        fieldId={`marital-status`}
                        hasError={validationErrors && validationErrors[`maritalStatus`]}
                        returnFieldValue={(value) => {setResidentPayload({...residentPayload, ...{maritalStatus: value.value}})}}
                      />
                    </div>
                  </div>

                  <div className='w-full mt-8'>
                    <h3 className="mt-[10px] font-[500] text-gray-700 mb-1">Resident's Contact details</h3>
                    <p className="text-sm text-opacity-80">You can fill each step of the form and save it to continue at a later time.</p>
                  </div>

                  <div className='mt-4 flex items-center justify-between gap-x-[20px]'>
                    <div className='w-full'>
                      <TextField
                        inputLabel="Email address" 
                        fieldId="email-address" 
                        inputType="text" 
                        preloadValue={''}
                        inputPlaceholder={'Active email address'}
                        hasError={validationErrors && validationErrors.firstName} 
                        returnFieldValue={(value)=>{setResidentPayload({...residentPayload, ...{email: value}})}}
                      />
                    </div>

                    <div className='w-full'>
                      <TextField
                        inputLabel="Phone number" 
                        fieldId="phone-number" 
                        inputType="text" 
                        preloadValue={''}
                        inputPlaceholder={'Active contact phone'}
                        hasError={validationErrors && validationErrors.phone} 
                        returnFieldValue={(value)=>{setResidentPayload({...residentPayload, ...{phone: value}})}}
                      />
                    </div>
                  </div>

                  <div className='mt-4'>
                      <TextField
                        inputLabel="Address" 
                        fieldId="facility-address" 
                        inputType="text" 
                        preloadValue={''}
                        inputPlaceholder={'Where is your facility located?'}
                        hasError={validationErrors && validationErrors.address} 
                        returnFieldValue={(value)=>{setResidentPayload({...residentPayload, ...{address: value}})}}
                      />
                  </div>

                  <div className='mt-4 flex items-center justify-between gap-x-[20px]'>
                      <div className='w-full'>
                        <TextField
                          inputLabel="City" 
                          fieldId="address-city" 
                          inputType="text" 
                          preloadValue={''}
                          inputPlaceholder={'City/Location'}
                          hasError={validationErrors && validationErrors.city} 
                          returnFieldValue={(value)=>{setResidentPayload({...residentPayload, ...{city: value}})}}
                        />
                      </div>
                      <div className='w-full'>
                        <TextField
                          inputLabel="Zip/Area Code" 
                          fieldId="address-zip-code" 
                          inputType="text" 
                          preloadValue={''}
                          inputPlaceholder={'Zip/Area Code'}
                          hasError={validationErrors && validationErrors.zip} 
                          returnFieldValue={(value)=>{setResidentPayload({...residentPayload, ...{zip: value}})}}
                        />
                      </div>
                      <div className='w-full'>
                        <TextField
                          inputLabel="State" 
                          fieldId="address-state" 
                          inputType="text" 
                          preloadValue={''}
                          inputPlaceholder={'State'}
                          hasError={validationErrors && validationErrors.state} 
                          returnFieldValue={(value)=>{setResidentPayload({...residentPayload, ...{state: value}})}}
                        />
                      </div>
                  </div>

                </>}

                {activeStep === 1 && <>
                  <div className='w-full mt-8'>
                    <div className='flex items-center gap-x-3'>
                      <p className="font-[550] text-gray-700 mb-1">Does the resident have any allergies?</p>

                      <div className='w-24'>
                        <Switch
                            checked={hasAllergy}
                            onChange={()=>{setHasAllergy(!hasAllergy)}}
                            className={`${
                              hasAllergy ? 'bg-doiteasy-blue' : 'bg-gray-200'
                            } relative inline-flex items-center h-5 rounded-full w-10`}
                            >
                            {/* <span className="sr-only">Display stock levels</span> */}
                            <span
                                className={`transform transition ease-in-out duration-200 ${
                                  hasAllergy ? 'translate-x-6' : 'translate-x-1'
                              } inline-block w-3 h-3 transform bg-white rounded-full`}
                            />
                        </Switch>
                      </div>
                    </div>
                    {hasAllergy && <>
                      <p className="text-sm text-opacity-80">You can add as many allergies as needed by clicking on the "add more" button</p>
                      {allergies.map((allergy, allergyIndex) => (<div key={allergyIndex} className='w-full mt-4 relative'>
                        {allergyIndex > 0 &&
                          <button onClick={()=>{removeAllergy(allergyIndex)}} className='absolute -top-[5px] right-0 z-20 p-[7px] text-gray-400 hover:bg-gray-100 hover:text-red-700 rounded transition duration-200'>
                            <TrashIcon className={`w-5 h-5`} />
                          </button>}
                        <TextField
                          inputLabel="Allergy" 
                          fieldId="allergy-name" 
                          inputType="text" 
                          preloadValue={allergy || ''}
                          inputPlaceholder={`Allergen name`}
                          hasError={validationErrors[`allergy-${allergyIndex}`]}
                          returnFieldValue={(value)=>{updateAllergy(allergyIndex, value)}}
                        />
                      </div>))}
                    
                      <button onClick={()=>{addAllergy()}} className='flex items-center justify-center w-max rounded-[8px] px-[16px] py-[10px] mt-4 text-sm bg-doiteasy-black text-doiteasy-light-gray'>
                        <PlusIcon className={`w-4 h-4`}/>
                        Add more
                      </button>
                    </>}
                  </div>

                  <div className='w-full mt-8'>
                    <div className='flex items-center gap-x-3'>
                      <p className="font-[550] text-gray-700 mb-1">Pre-existing medical conditions</p>
                      <div className='w-24'>
                        <Switch
                            checked={hasPreExistingCondition}
                            onChange={()=>{setHasPreExistingCondition(!hasPreExistingCondition)}}
                            className={`${
                              hasPreExistingCondition ? 'bg-doiteasy-blue' : 'bg-gray-200'
                            } relative inline-flex items-center h-5 rounded-full w-10`}
                            >
                            {/* <span className="sr-only">Display stock levels</span> */}
                            <span
                                className={`transform transition ease-in-out duration-200 ${
                                  hasPreExistingCondition ? 'translate-x-6' : 'translate-x-1'
                              } inline-block w-3 h-3 transform bg-white rounded-full`}
                            />
                        </Switch>
                      </div>
                    </div>
                    {hasPreExistingCondition && <>
                      <p className="text-sm text-opacity-80">You can add as many conditions as needed by clicking on the "add more" button</p>
                      {preExistingConditions.map((condition, conditionIndex)=>(<div key={conditionIndex} className='w-full mt-4 relative'>
                        {conditionIndex > 0 &&
                          <button onClick={()=>{removeCondition(conditionIndex)}} className='absolute -top-[5px] right-0 z-20 p-[7px] text-gray-400 hover:bg-gray-100 hover:text-red-700 rounded transition duration-200'>
                            <TrashIcon className={`w-5 h-5`} />
                          </button>
                        }
                        <TextField
                          inputLabel="Condition" 
                          fieldId="allergy-name" 
                          inputType="text" 
                          preloadValue={condition || ''}
                          inputPlaceholder={`Medical condition name`}
                          hasError={validationErrors[`condition-${conditionIndex}`]}
                          returnFieldValue={(value)=>{updateCondition(conditionIndex, value)}}
                        />
                      </div>))}
                    
                      <button onClick={()=>{addCondition()}} className='flex items-center justify-center w-max rounded-[8px] px-[16px] py-[10px] mt-4 text-sm bg-doiteasy-black text-doiteasy-light-gray'>
                        <PlusIcon className={`w-4 h-4`}/>
                        Add more
                      </button>
                    </>}
                  </div>

                  <div className='w-full mt-8'>
                    <p className="mt-8 font-[550] text-gray-700 mb-1">Resident's Primary Physician</p>
                    <p className="text-sm text-opacity-80">Please provide details of this resident's primary physician below.</p>

                    <div className='w-full'>
                      <TextField
                        inputLabel="Physician name" 
                        fieldId="physician-name" 
                        inputType="text" 
                        preloadValue={''}
                        inputPlaceholder={'Physician name'}
                        hasError={validationErrors && validationErrors.physicianName} 
                        returnFieldValue={(value)=>{setPrimaryPhysician({...primaryPhysician, ...{name: value}})}}
                      />
                    </div>

                    <div className='w-full mt-4'>
                      <TextField
                        inputLabel="Hospital name" 
                        fieldId="physician-name" 
                        inputType="text" 
                        preloadValue={''}
                        inputPlaceholder={`Name of Physician's hospital of work`}
                        hasError={validationErrors && validationErrors.physicianHospital} 
                        returnFieldValue={(value)=>{setPrimaryPhysician({...primaryPhysician, ...{hospital: value}})}}
                      />
                    </div>

                    <div className='mt-4 flex items-center justify-between gap-x-[20px]'>
                      <div className='w-full'>
                        <TextField
                          inputLabel="Email address" 
                          fieldId="email-address" 
                          inputType="text" 
                          preloadValue={''}
                          inputPlaceholder={'Active email address'}
                          hasError={validationErrors && validationErrors.physicianEmail} 
                          returnFieldValue={(value)=>{setPrimaryPhysician({...primaryPhysician, ...{email: value}})}}
                        />
                      </div>

                      <div className='w-full'>
                        <TextField
                          inputLabel="Phone number" 
                          fieldId="phone-number" 
                          inputType="text" 
                          preloadValue={''}
                          inputPlaceholder={'Active contact phone'}
                          hasError={validationErrors && validationErrors.physicianPhone} 
                          returnFieldValue={(value)=>{setPrimaryPhysician({...primaryPhysician, ...{phone: value}})}}
                        />
                      </div>
                    </div>

                  </div>

                  
                </>}

                {activeStep === 2 && <>
                  <div className='w-full mt-8'>
                    <div className='mt-4 flex items-center justify-between gap-x-[20px]'>
                      <div className='w-full'>
                        <SelectField
                          selectOptions={relationships}
                          inputLabel="Relationship"
                          placeholderText={`Relationship with Next of Kin`}
                          titleField="label"
                          displayImage={false}
                          imageField=""
                          // preSelectedIndex={complexions.findIndex(item => item.value === applicationPayload.complexion)}
                          preSelectedIndex={null}
                          fieldId={`nok-relationship`}
                          hasError={validationErrors && validationErrors[`nokRelationship`]}
                          returnFieldValue={(value) => {setNokPayload({...nokPayload, ...{relationship: value.value}})}}
                        />
                      </div>

                      <div className='w-full'>
                        <RadioGroup
                          items={genders}
                          inputLabel="Gender"
                          titleField="label"
                          displayImage={false}
                          imageField=""
                          // preSelectedIndex={complexions.findIndex(item => item.value === applicationPayload.complexion)}
                          preSelectedIndex={null}
                          fieldId={`title`}
                          inline={true}
                          hasError={validationErrors && validationErrors[`nokGender`]}
                          showDescriptions={true}
                          returnSelected={(value) => {setNokPayload({...nokPayload, ...{gender: value.value}})}}
                        />
                      </div>
                    </div>

                    <div className='w-full mt-4'>
                      <TextField
                        inputLabel="Next of Kin's Name" 
                        fieldId="nok-name" 
                        inputType="text" 
                        preloadValue={''}
                        inputPlaceholder={'Given name'}
                        hasError={validationErrors && validationErrors.nokName} 
                        returnFieldValue={(value)=>{setNokPayload({...nokPayload, ...{name: value}})}}
                      />
                    </div>

                    <div className='mt-4 flex items-center justify-between gap-x-[20px]'>
                      <div className='w-full'>
                        <TextField
                          inputLabel="Email address" 
                          fieldId="email-address" 
                          inputType="text" 
                          preloadValue={''}
                          inputPlaceholder={'Active email address'}
                          hasError={validationErrors && validationErrors.nokEmail} 
                          returnFieldValue={(value)=>{setNokPayload({...nokPayload, ...{email: value}})}}
                        />
                      </div>

                      <div className='w-full'>
                        <TextField
                          inputLabel="Phone number" 
                          fieldId="phone-number" 
                          inputType="text" 
                          preloadValue={''}
                          inputPlaceholder={'Active contact phone'}
                          hasError={validationErrors && validationErrors.nokPhone} 
                          returnFieldValue={(value)=>{setNokPayload({...nokPayload, ...{phone: value}})}}
                        />
                      </div>
                    </div>

                  </div>
                </>}

                {activeStep === 3 && <>
                  <p className="text-sm text-opacity-80 mt-4">You can add as many emergency contacts as needed by clicking on the "add another contact" button</p>

                  {emergencyContacts.map((contact, contactIndex)=>(<div key={contactIndex} className='w-full mt-8 relative'>
                    <h3 className='text-[15px] font-[550] text-gray-500'>Contact {contactIndex + 1}</h3>
                    {contactIndex > 0 &&
                      <button onClick={()=>{deleteEmergencyContact(contactIndex)}} className='absolute -top-[10px] right-0 z-20 p-[7px] text-gray-400 hover:bg-gray-100 hover:text-red-700 rounded transition duration-200'>
                        <TrashIcon className={`w-5 h-5`} />
                      </button>}
                    <div className='w-full mt-4'>
                      <TextField
                        inputLabel="Name" 
                        fieldId="nok-name" 
                        inputType="text" 
                        preloadValue={contact.name || ''}
                        inputPlaceholder={'Emergency Contact name'}
                        hasError={validationErrors && validationErrors[`contact-${contactIndex}-name`]} 
                        returnFieldValue={(value)=>{updateEmergencyContact(contactIndex, 'name', value)}}
                      />
                    </div>

                    <div className='mt-4 flex items-center justify-between gap-x-[20px]'>
                      <div className='w-full'>
                        <TextField
                          inputLabel="Email address" 
                          fieldId="email-address" 
                          inputType="text" 
                          preloadValue={contact.email || ''}
                          inputPlaceholder={'Active email address'}
                          hasError={validationErrors && validationErrors[`contact-${contactIndex}-email`]} 
                          returnFieldValue={(value)=>{updateEmergencyContact(contactIndex, 'email', value)}}
                        />
                      </div>

                      <div className='w-full'>
                        <TextField
                          inputLabel="Phone number" 
                          fieldId="phone-number" 
                          inputType="text" 
                          preloadValue={contact.phone || ''}
                          inputPlaceholder={'Active contact phone'}
                          hasError={validationErrors && validationErrors[`contact-${contactIndex}-phone`]} 
                          returnFieldValue={(value)=>{updateEmergencyContact(contactIndex, 'phone', value)}}
                        />
                      </div>
                    </div>
                  </div>))}

                  <button onClick={()=>{addEmergencyContact()}} className='flex items-center justify-center w-max rounded-[8px] px-[16px] py-[10px] mt-6 text-sm bg-doiteasy-black text-doiteasy-light-gray'>
                      <PlusIcon className={`w-4 h-4`}/>
                      Add another contact
                    </button>
                </>}

                {activeStep === 4 && <>
                  <p className="text-sm text-opacity-80 mt-4">You can add as notes as needed by clicking on the "add another note" button</p>

                  {notes.map((note, noteIndex)=>(<div key={noteIndex} className='w-full mt-5 relative'>
                    <h3 className='text-[15px] font-[550] text-gray-500'>Note {noteIndex + 1}</h3>
                    {noteIndex > 0 &&
                      <button onClick={()=>{removeNote(noteIndex)}} className='absolute top-0 right-0 z-20 p-[7px] text-gray-400 hover:bg-gray-100 hover:text-red-700 rounded transition duration-200'>
                        <TrashIcon className={`w-5 h-5`} />
                      </button>}
                    <div className='w-full mt-'>
                      <TextareaField
                        inputLabel="Note" 
                        fieldId={`note-${noteIndex}`} 
                        inputType="text" 
                        preloadValue={note || ''}
                        inputPlaceholder={'Note'}
                        hasError={validationErrors && validationErrors[`note-${noteIndex}-name`]} 
                        returnFieldValue={(value)=>{updateNote(noteIndex, value)}}
                      />
                    </div>
                  </div>))}

                  <button onClick={()=>{addNote()}} className='flex items-center justify-center w-max rounded-[8px] px-[16px] py-[10px] mt-6 text-sm bg-doiteasy-black text-doiteasy-light-gray'>
                    <PlusIcon className={`w-4 h-4`}/>
                    Add another note
                  </button>

                  <h3 className='text-[15px] font-[550] text-gray-500 mt-[40px] text-lg mb-[10px]'>Resident's Consents</h3>
                  <p className='text-gray-600 font-[500] text-sm'>Please ensure that the residents or their families understand the terms below</p>

                  <div className='w-full mt-4'>
                    <Checkbox
                        CheckboxLabel="The resident has reviewed and agrees to care home rules, policies, and procedures"
                        checkboxToggleFunction={()=>{
                          updateConsent('termsOfStay', !consentAcceptance.termsOfStay)
                        }} 
                        isChecked={consentAcceptance.termsOfStay} 
                        hasError={validationErrors.termsOfStay} 
                    />
                  </div>

                  <div className='w-full mt-4'>
                    <Checkbox
                        CheckboxLabel="The resident consents to receiving first aid if required before being transferred to their primary physician"
                        checkboxToggleFunction={()=>{
                          updateConsent('firstAid', !consentAcceptance.firstAid)
                        }} 
                        isChecked={consentAcceptance.firstAid} 
                        hasError={false} 
                    />
                  </div>

                  <div className='w-full mt-4'>
                    <Checkbox
                        CheckboxLabel="Resident consents to the care home collecting, storing, and using their personal information in accordance with data protection laws, solely for the purpose of providing appropriate care and services."
                        checkboxToggleFunction={()=>{
                          updateConsent('dataPrivacyAndUse', !consentAcceptance.dataPrivacyAndUse)
                        }} 
                        isChecked={consentAcceptance.dataPrivacyAndUse} 
                        hasError={false} 
                    />
                  </div>
                  
                </>}
            </div>

            <div className='flex flex-row-reverse items-center justify-between mt-8'>
              <div className='w-max'>
                <FormButton 
                  buttonLabel={
                    activeStep < 4 ? <span className='flex items-center justify-center gap-x-[10px] text-white font-space-grotesk font-[550]'>
                      Save & Continue
                      <ArrowIcon className={`w-5 h-5`} />
                    </span>
                    :
                    <span className='flex items-center justify-center gap-x-[10px] text-white font-space-grotesk font-[550]'>
                      Complete Onboarding
                      <CheckIcon className={`w-5 h-5`} />
                    </span>
                  } 
                  // buttonAction={()=>{setActiveStep(activeStep+1)}} 
                  buttonAction={()=>{pushResident()}} 
                  processing={residentsSelector.creatingResident} />
              </div>

              {activeStep > 0 && <button onClick={()=>{setActiveStep(activeStep - 1)}} className='flex items-center justify-center gap-x-[5px] text-sm font-[500] text-gray-500'>
                <ArrowIcon className={`w-4 h-4 -rotate-180`} />
                Previous Step
              </button>}
            </div>
          </div>
        </div>
    </Layout>
  )
}

export default NewResident