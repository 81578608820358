import React, { useState } from 'react'

const DateField = ({
    requiredField,
    inputLabel, 
    fieldId, 
    hasError, 
    returnFieldValue, 
    preloadValue, 
    disabled, 
}) => {
    const [ fieldValue, setFieldValue ] = useState(preloadValue)


    const setValue = (value) => {
        setFieldValue(value)
        returnFieldValue(value)
    }

    return (
        <div>

            <label 
                className={`text-sm lg:text-md cursor-text z-10 relative mb-1 block py-1 transition duration-200 font-[500]  
                ${hasError ? 'text-red-600' : 'text-gray-500'}`}
            >
             {requiredField && requiredField === true && <span className='text-red-600'>*</span>}   {inputLabel}
            </label>
            <input 
                id={fieldId} 
                type="date" 
                className={`rounded-[8px] py-3 px-3 text-sm block w-full focus:border-gray-800 focus:outline-none hover:border-gray-200 hover:bg-gray-50 border bg-gray-100 transition duration-200 focus:bg-white font-outfit placeholder:font-outfit ${hasError ? 'border-red-600' : 'border-gray-400'}`} 
                onChange={(e)=>{setValue(e.target.value)}}
                value={fieldValue}
                disabled={disabled}
            /> 
        </div>
    )
}

export default DateField