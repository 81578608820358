import { combineReducers } from "redux";
import { errorReducer } from "./errorReducer";
import successReducer from "./successReducer";
import staffReducer from "./staffReducer";
import rolesPermissionsReducer from "./rolesPermissionsReducer";
import auditLogsReducer from "./auditLogsReducer";
import residentsReducer from "./residentsReducer";

const rootReducer = combineReducers({
    success: successReducer,
    errors: errorReducer,
    staff: staffReducer,
    roles: rolesPermissionsReducer,
    auditLogs: auditLogsReducer,
    residents: residentsReducer
});

export default rootReducer;