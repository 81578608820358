import React from 'react'
import OpenFolderIcon from '../../assets/images/icons/open-folder.svg'

const EmptyState = ({emptyStateTitle, emptyStateText}) => {
  return (
    <div className='min-h-[100px] h-inherit flex flex-col items-center text-center'>
        <div className='w-[100px] h-[100px] rounded-full bg-gradient-to-b from-gray-100 to-white flex items-center justify-center'>
          <img src={OpenFolderIcon} className='w-1/2' alt='' />
        </div>
        <p className='font-space-grotesk text-doiteasy-black mt-[10px] font-[550]'>{emptyStateTitle}</p>
        <p className='text-sm text-gray-500'>{emptyStateText}</p>
    </div>
  )
}

export default EmptyState