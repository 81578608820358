import axios from "axios"
import { authHeader, baseUrl } from "../../utils"
import { CREATE_RESIDENT, CREATING_RESIDENT, GET_RESIDENTS, GETTING_RESIDENTS, RESIDENTS_ERROR, UPDATE_RESIDENT, UPDATING_RESIDENT } from "../types"


export const createResident = (residentPayload) => async (dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: CREATING_RESIDENT,
            payload: true
        })
        const response = await axios.post(`${baseUrl}/residents`, residentPayload, { headers })
        
        dispatch({
            type: CREATE_RESIDENT,
            payload: response.data.data
        })
        
    }
    catch(error){
        console.log(error)
        dispatch({
            type: RESIDENTS_ERROR,
            error
        })
    }
}

export const updateResident = (residentId, residentPayload) => async (dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: UPDATING_RESIDENT,
            payload: true
        })
        const response = await axios.patch(`${baseUrl}/roles/${residentId}`, residentPayload, { headers })
        
        dispatch({
            type: UPDATE_RESIDENT,
            payload: response.data.data
        })
        
    }
    catch(error){
        console.log(error)
        dispatch({
            type: RESIDENTS_ERROR,
            error
        })
    }
}

export const fetchResidents = (filterString, page, perPage) => async (dispatch) => {    
    try{
        const headers = authHeader()

        let url = `${baseUrl}/residents`
        if(filterString && filterString !== '') {
            url += `${url.includes('?') ? '&' : '?'}${filterString}`
        }

        if(page && page!=='') {
            url += `${url.includes('?') ? '&' : '?'}page=${page}`
        }

        if(perPage && perPage!=='') {
            url += `${url.includes('?') ? '&' : '?'}perPage=${perPage}`
        }

        dispatch( {
            type: GETTING_RESIDENTS,
            payload: true
        })

        const response = await axios.get(url, { headers })

        dispatch({
            type: GET_RESIDENTS,
            payload: response.data.data
        })
        
    }
    catch(error){
        dispatch( {
            type: RESIDENTS_ERROR,
            error
        })
    }
}

export const clearCreatedResident = () => async (dispatch) => {    
    dispatch({
        type: CREATE_RESIDENT,
        payload: null
    })
}

export const clearUpdatedResident = () => async (dispatch) => {    
    dispatch({
        type: UPDATE_RESIDENT,
        payload: null
    })
}
